const allVendors = [
    {
        header: "dm",
        name: "DumpMedia",
        vendor: "dumpmedia",
        email: "@dumpmedia.com",
        logo: require("@/assets/img/dumpMedia/dm-logo.png"),
        analyticInterface: "https://parse.dumpmedia.com/parse/classes/web",
        officialLink: {
            en_US: "https://www.dumpmedia.com/",
            ja_JP: "https://www.dumpmedia.jp/",
            zh_TW: "https://www.dumpmedia.com/zh-TW/",
            fr_FR: "https://www.dumpmedia.com/fr/",
            de_DE: "https://www.dumpmedia.com/de/",
            es_ES: "https://www.dumpmedia.com/es/",
            pt_PT: "https://www.dumpmedia.com/pt/",
            it_IT: "https://www.dumpmedia.com/it/",
            ko_KR: "https://www.dumpmedia.com/ko/",
            nl_NL: "https://www.dumpmedia.com/nl/",
            pl_PL: "https://www.dumpmedia.com/pl/",
        },
        supportLink: "https://dumpmedia.com/support/",
        hasGoogleLogin: true,
        googleClientId:
            "414495660877-nvum2odsoin85pf7kbf5h1718348fivi.apps.googleusercontent.com",
        indexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "services",
            "subaccount",
            "updatePwd",
            "cart",
        ],
        proIndexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "updatePwd",
        ],
    },
    {
        header: "tf",
        name: "TuneFab",
        vendor: "tunefab",
        email: "@tunefab.com",
        logo: require("@/assets/img/website/tunefab/logo.svg"),
        analyticInterface: "https://parse.tunefab.com/parse/classes/web",
        officialLink: {
            en_US: "https://www.tunefab.com/",
            ja_JP: "https://www.tunefab.jp/",
            zh_TW: "https://www.tunefab.tw/",
            fr_FR: "https://www.tunefab.net/",
            de_DE: "https://www.tunefab.de/",
            es_ES: "https://www.tunefab.com/es/",
            pt_PT: "https://www.tunefab.com/pt/",
            it_IT: "https://www.tunefab.com/it/",
            ko_KR: "https://www.tunefab.com/ko/",
            nl_NL: "https://www.tunefab.com/nl/",
            pl_PL: "https://www.dumpmedia.com/",
        },
        supportLink: "https://support.tunefab.com/",
        hasGoogleLogin: true,
        googleClientId:
            "125046220915-o33njocoqaosng02vqsvo05o0rhipq1f.apps.googleusercontent.com",
        personalizedDisplay: true,
        // 管理设备页面，用套餐做下拉
        planSearch: true,
        indexModules: [
            "userInfo",
            "subscription",
            "manage",
            "contact",
            "updatePwd",
        ],
    },
    {
        header: "sf",
        name: "StreamFox",
        vendor: "streamfox",
        email: "@streamfox.com",
        logo: require("@/assets/img/website/streamfox/logo.svg"),
        analyticInterface: "https://parse.tunefab.com/parse/classes/web",
        officialLink: {
            en_US: "https://www.streamfox.net/",
        },
        supportLink: "https://www.streamfox.net/",
        hasGoogleLogin: true,
        googleClientId:
            "123509768644-0qknk8ftbsbk1tvs1lh8kc6frdbma097.apps.googleusercontent.com",
        preGoogleClientId:
            "123509768644-ni1vc3punh8vt75679ckorjot01j6pv8.apps.googleusercontent.com",
        personalizedDisplay: true,
        planSearch: true,
        indexModules: [
            "userInfo",
            "subscription",
            "manage",
            "contact",
            "updatePwd",
        ],
    },
    {
        header: "ts",
        name: "TuneSolo",
        vendor: "tunesolo",
        email: "@tunesolo.com",
        logo: require("@/assets/img/website/tunesolo/logo.png"),
        analyticInterface: "https://parse.tunesolo.com/parse/classes/web",
        officialLink: {
            en_US: "https://www.tunesolo.com/",
            ja_JP: "https://www.tunesolo.jp/",
            zh_TW: "https://www.tunesolo.com/zh-TW/",
            fr_FR: "https://www.tunesolo.com/fr/",
            de_DE: "https://www.tunesolo.com/de/",
            es_ES: "https://www.tunesolo.com/es/",
            pt_PT: "https://www.tunesolo.com/pt/",
            it_IT: "https://www.tunesolo.com/it/",
            ko_KR: "https://www.tunesolo.com/ko/",
            nl_NL: "https://www.tunesolo.com/nl/",
            pl_PL: "https://www.tunesolo.com/pl/",
        },
        supportLink: "https://www.tunesolo.com/support/",
        hasGoogleLogin: true,
        googleClientId:
            "279109749777-l4hgiprsttfk33sqikhdogu6a52jmebn.apps.googleusercontent.com",
        indexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "services",
            "subaccount",
            "updatePwd",
            "cart",
        ],
        proIndexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "updatePwd",
        ],
    },
    {
        header: "ams",
        name: "AMusicSoft",
        vendor: "amusicsoft",
        email: "@amusicsoft.com",
        logo: require("@/assets/img/website/ams/logo.png"),
        analyticInterface: "https://parse.amusicsoft.com/parse/classes/web",
        officialLink: {
            en_US: "https://www.amusicsoft.com/",
            ja_JP: "https://www.amusicsoft.com/ja/",
            zh_TW: "https://www.amusicsoft.com/zh-TW/",
            fr_FR: "https://www.amusicsoft.com/fr/",
            de_DE: "https://www.amusicsoft.com/de/",
            es_ES: "https://www.amusicsoft.com/es/",
            pt_PT: "https://www.amusicsoft.com/pt/",
            it_IT: "https://www.amusicsoft.com/it/",
            ko_KR: "https://www.amusicsoft.com/ko/",
            nl_NL: "https://www.amusicsoft.com/nl/",
            pl_PL: "https://www.amusicsoft.com/pl/",
        },
        supportLink: "https://www.tunesolo.com/support/",
        hasGoogleLogin: true,
        googleClientId:
            "288944442514-s8qgvlpu0ubm0rk6m8t5h1r270ajp7gj.apps.googleusercontent.com",
        indexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "services",
            "subaccount",
            "updatePwd",
            "cart",
        ],
        proIndexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "updatePwd",
        ],
    },
    {
        header: "tsf",
        name: "TunesFun",
        vendor: "tunesfun",
        email: "@tunesfun.com",
        logo: require("@/assets/img/website/tunesfun/logo.png"),
        analyticInterface: "https://parse.tunesfun.com/parse/classes/web",
        officialLink: {
            en_US: "https://www.tunesfun.com/",
            ja_JP: "https://www.tunesfun.com/ja/",
            zh_TW: "https://www.tunesfun.com/zh-TW/",
            fr_FR: "https://www.tunesfun.com/fr/",
            de_DE: "https://www.tunesfun.com/de/",
            es_ES: "https://www.tunesfun.com/es/",
            pt_PT: "https://www.tunesfun.com/pt/",
            it_IT: "https://www.tunesfun.com/it/",
            ko_KR: "https://www.tunesfun.com/ko/",
            nl_NL: "https://www.tunesfun.com/nl/",
            pl_PL: "https://www.tunesfun.com/pl/",
        },
        supportLink: "https://www.tunesolo.com/support/",
        hasGoogleLogin: true,
        googleClientId:
            "852707878723-8k419f3dje7qdofr9sp99t8c6s17c9gk.apps.googleusercontent.com",
        indexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "services",
            "subaccount",
            "updatePwd",
            "cart",
        ],
        proIndexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "updatePwd",
        ],
    },
    {
        header: "tp",
        name: "TubePat",
        vendor: "tubepat",
        email: "@tubepat.com",
        logo: require("@/assets/img/website/tubepat/logo.svg"),
        analyticInterface: "https://parse.tubepat.com/parse/classes/web",
        officialLink: {
            en_US: "https://www.tubepat.com/",
        },
        supportLink: "https://www.tubepat.com/support/",
        hasGoogleLogin: true,
        googleClientId:
            "785122181535-rvm4o8q8rohdjst9ktfpdhq621jlcmb6.apps.googleusercontent.com",
        indexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "services",
            "subaccount",
            "updatePwd",
            "cart",
        ],
        proIndexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "updatePwd",
        ],
    },
    {
        header: "vh",
        name: "VideoHunter",
        vendor: "videohunter",
        email: "@videohunter.com",
        logo: require("@/assets/img/website/videohunter/logo.svg"),
        analyticInterface: "https://parse.vidpaw.com/parse/classes/web",
        officialLink: {
            en_US: "https://www.videohunter.net/",
            ja_JP: "https://www.videohunter.net/ja/",
            zh_TW: "https://www.videohunter.tw/",
            fr_FR: "https://www.videohunter.net/fr/",
            de_DE: "https://www.videohunter.net/de/",
            es_ES: "https://www.videohunter.net/es/",
            pt_PT: "https://www.videohunter.net/pt/",
            it_IT: "https://www.videohunter.net/it/",
            nl_NL: "https://www.videohunter.net/nl/",
            pl_PL: "https://www.videohunter.net/pl/",
        },
        supportLink: "https://www.videohunter.net/support/",
        hasGoogleLogin: true,
        googleClientId:
            "414495660877-nvum2odsoin85pf7kbf5h1718348fivi.apps.googleusercontent.com",
        indexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "services",
            "subaccount",
            "updatePwd",
            "cart",
        ],
        proIndexModules: [
            "userInfo",
            "subscription",
            "manage",
            "billing",
            "contact",
            "updatePwd",
        ],
    },
];

const axios = require("axios");
async function getVendor() {
    const defaultVendor = process.env.VUE_APP_VENDOR
        ? allVendors.find(
              (item) => process.env.VUE_APP_VENDOR.toLowerCase() == item.vendor
          )
        : {};
    try {
        const response = await axios.get(window.reqUrl);
        const h = response.headers["cli-h"];
        const currentVendor = h
            ? allVendors.find((item) => h == item.header)
            : undefined;
        return currentVendor ? currentVendor : defaultVendor;
    } catch (error) {
        return defaultVendor;
    }
}

export default getVendor;
